<script>
import { getCurrentUserId } from '../../js/auth.js';
import { functions } from '../../js/lib/functions.js'
import { navigate } from '@trullock/page-manager'
import Modal from '../../js/utils/modal.js';
import Cropper from 'cropperjs'
import DateTime from '../../../functions/lib/dateTime.js';
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { showToast } from '../../js/utils/toast.js';
import { getUserView } from '../../js/readModel.js';

export const pageName = 'account';
export const pageRoute = '/account';
export const title = 'Account';
export const requireEmailVerified = false;

export default {
	data() {
		return { 
			user: null,
			name: '',
			email: '',
			bornOn: null,
			BMFANumber: '',
			BMFASurname: '',
			avatar: null,
			
			files: [],

			ready: false,
			maxDob: DateTime.today,
			minDob: DateTime.today.addYears(-120)
		}
	},
	methods: {
		async boot(opts) {
			this.modal = new Modal(this.$refs.cropper);
		},
		async show()
		{
			functions.warmUp.userChangeName();
			functions.warmUp.userChangeBMFA();
			functions.warmUp.userChangeBornOn();
			functions.warmUp.userChangeAvatar();

			this.user = await getUserView(getCurrentUserId());

			this.name = this.user.name;
			this.email = this.user.email;
			this.avatar = this.user.avatar;
			this.bornOn = this.user.bornOn?.format('yyyy-MM-dd');
			this.BMFANumber = this.user.BMFANumber;
			this.BMFASurname = this.user.BMFASurname;
			this.files = [];
			
			this.ready = true;
		},
		async submit() {
			
			showLoading();

			this.$refs.bmfaNumber.setCustomValidity('');
			this.$refs.bmfaSurname.setCustomValidity('');

			let result = await functions.queryBMFA({
				surname: this.BMFASurname,
				number: this.BMFANumber
			})

			if(result.success)
			{
				if(result.status == 'expired')
				{
					this.$refs.bmfaSurname.setCustomValidity(' ');
					this.$refs.bmfaSurname.reportValidity();

					this.$refs.bmfaNumber.setCustomValidity('Insurance is not active');
					this.$refs.bmfaNumber.reportValidity();

					hideLoading();
					return;
				}
				else if(result.status == 'not-found')
				{
					this.$refs.bmfaSurname.setCustomValidity(' ');
					this.$refs.bmfaSurname.reportValidity();

					this.$refs.bmfaNumber.setCustomValidity('Insurance details not found');
					this.$refs.bmfaNumber.reportValidity();

					hideLoading();
					return;
				}
			}

			// new need copies because the update listener replaces the new values
			let newName = this.name;
			let newBMFANumber = this.BMFANumber;
			let newBMFASurname = this.BMFASurname;
			let newBornOn = new DateTime(this.bornOn);

			result = await functions.userChangeName({ name: newName});
			if(!result.success)
			{
				showToast({ message: 'Error changing your name', style: 'bg-danger'})
				hideLoading();
				return;
			}

			result = await functions.userChangeBMFA({ 
				number: newBMFANumber,
				surname: newBMFASurname
			});
			if(!result.success)
			{
				showToast({ message: 'Error changing your BMFA details', style: 'bg-danger'})
				hideLoading();
				return;
			}

			result = await functions.userChangeBornOn({ bornOn: newBornOn })
			if(!result.success)
			{
				showToast({ message: 'Error changing your date of birth', style: 'bg-danger'})
				hideLoading();
				return;
			}

			if(this.newAvatar)
			{
				result = await functions.userChangeAvatar({ avatar64: this.newAvatar});
				if(!result.success)
				{
					showToast({ message: 'Error changing your avatar', style: 'bg-danger'})
					hideLoading();
					return;
				}
			}

			navigate('/')

			showToast({
				message: 'Account updated',
				style: 'bg-success'
			})

			hideLoading();
		},
		hide()
		{
			this.$refs.fupAvatar.value = null
			this.$refs.lblAvatar.innerHTML = "Choose image&hellip;";
		},
		crop() {
			showLoading();

			this.modal.hide();

			// use a separate "thread" because of UI locking
			setTimeout(() => {
				
				let canvas = this.cropper.getCroppedCanvas({
					width: 240,
					height: 240
				});

				this.avatar = canvas.toDataURL();
				this.newAvatar = this.avatar;

				canvas.toBlob(blob => {
					this.$refs.fupAvatar.blobData = {
						blob: blob,
						name: 'avatar.jpg'
					};
					this.$refs.lblAvatar.innerText = this.$refs.lblAvatar.pendingName;
				});
				
				hideLoading();

			}, 1);
		},
		hideModal() {
			this.modal.hide()
			this.cropper.destroy();
			this.cropper = null;
		},
		inputChanged(e) {

			var files = e.target.files;
			var done = (url, name) => {
				this.$refs.fupAvatar.value = '';
				this.$refs.fupAvatar.blobData = null;
				this.$refs.imgCropper.src = url;

				let $img = new Image();
				$img.addEventListener('load', e => {
					this.$refs.lblAvatar.innerHTML = "Choose image&hellip;";
					this.$refs.lblAvatar.pendingName = name;
					if(this.cropper)
						this.cropper.destroy();

					let maxHeight = window.innerHeight - 230;
					this.$refs.imgCropper.style.maxHeight = maxHeight + 'px'

					this.modal.show();
					this.cropper = new Cropper(this.$refs.imgCropper, {
						aspectRatio: 1,
						viewMode: 1,
						autoCropArea: 1
					});
				})

				$img.addEventListener('error', e => {
					showToast({
						message: 'File not a recognised image',
						style: 'bg-danger'
					})
				})

				$img.src = url;
				
			};

			if (files && files.length > 0) {
				let file = files[0];
				if (URL) {
					done(URL.createObjectURL(file), file.name);
				} else if (FileReader) {
					let reader = new FileReader();
					reader.onload = function (e) {
						done(reader.result, file.name);
					};
					reader.readAsDataURL(file);
				}
			}
		}
	},
	props: [ 'options' ]
}
</script>
<template>
<div class="container" v-if="ready">
    <form @submit.prevent="submit">
		<div class="row justify-content-center">
			<div class="col-12 col-md-6 my-5">
				<h1 class="display-4 text-center mb-3">Profile</h1>
				<p class="text-muted text-center mb-5">Change your details.</p>
					
				<fieldset>
					<div class="form-group">
						<label>Name</label>
						<input type="text" class="form-control" name="name" v-model="name" autocomplete="name" placeholder="First Last" required />
						<span class="invalid-feedback"></span>
					</div>
					<div class="form-group">
						<label>Date of birth</label>
						<input type="date" class="form-control" ref="dob" v-model="bornOn" required :max="`${maxDob.format('yyyy-MM-dd')}`" :min="`${minDob.format('yyyy-MM-dd')}`"/>
						<span class="invalid-feedback"></span>
					</div>
				</fieldset>
				<fieldset>
					<div class="field form-group mb-0">
						<label class="d-block">
							Photo ID
							
							<div class="row justify-content-center">
								<div class="col">
									<div class="dz-default dz-message py-4">
										<div class="avatar avatar-xxl">
											<img ref="imgAvatar" class="avatar-img rounded-circle" :src="avatar" alt="Avatar">
										</div>
									</div>
								</div>
							</div>
						</label>
						<div class="alert alert-secondary small"><span class="fe fe-alert-triangle"></span> Your picture must be a good quality portrait of yourself and will be used for membership verification at launches.</div>
						<div class="custom-file">
							<input type="file" @change="inputChanged" ref="fupAvatar" class="custom-file-input" accept="image/*;capture=camera">
							<label ref="lblAvatar" class="custom-file-label text-muted">Choose image&hellip;</label>
							<span class="invalid-feedback"></span>
						</div>
						<span class="form-text text-muted small">Must be a jpeg or png</span>
					</div>
				</fieldset>
			
				<hr class="my-5">
				<fieldset class="mb-4">
					<div class="row">
						<div class="col">
							<div class="form-group mb-0">
								<label>BMFA Number</label>
								<input type="text" class="form-control" ref="bmfaNumber" v-model="BMFANumber" placeholder="e.g. 123456" />
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col">
							<div class="form-group mb-0">
								<label>Surname</label>
								<input type="text" class="form-control" ref="bmfaSurname" v-model="BMFASurname" placeholder="e.g. Smith" />
								<span class="invalid-feedback"></span>
							</div>
						</div>
					</div>
					<span class="form-text text-muted small">Provide your number and surname from your BMFA insurance. See <a href="https://bmfa.org/join-us" target="_blank">https://bmfa.org/join-us</a> for more information.</span>
				</fieldset>

				<button type="submit" class="btn btn-lg btn-primary btn-block">Save changes <span class="fe fe-check-circle"></span></button>
			</div>
		</div>
	</form>
</div>
<div class="modal fade" ref="cropper" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card mb-0">
                <div class="card-header">
                    <h4 class="card-header-title">Crop the image</h4>
                    <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="card-body">
                    <div class="img-container">
                        <img ref="imgCropper" :src="avatar">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click.prevent="hideModal">Cancel</button>
                    <button type="button" class="btn btn-primary" @click.prevent="crop">Crop</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>