import { getMap } from "../../../functions/domain/map/map.js";

export async function getUserMap(user)
{
	let data = getMap(user);

	await Promise.all(data.levels.map(async l => {
		await Promise.all(l.subjects.map(async s => {
			await Promise.all(s.tasks.map(async t => {
				
				let img = await import(`../../img/map/${t.bgColor}.png`)
				t.bg = img.default
				
				img = await import(`../../img/map/${t.taskId.replaceAll('/', '_').toLowerCase()}.png`)
				t.icon = img.default;
			}))
		}))
	}))

	await Promise.all(data.bonuses.map(async t => {
				
		let img = await import(`../../img/map/${t.bgColor}.png`)
		t.bg = img.default
		
		img = await import(`../../img/map/${t.taskId.replaceAll('/', '_').toLowerCase()}.png`)
		t.icon = img.default;
	}))

	await Promise.all(data.completedTasks.map(async t => {
				
		let img = await import(`../../img/map/${t.bgColor}.png`)
		t.bg = img.default
		
		img = await import(`../../img/map/${t.taskId.replaceAll('/', '_').toLowerCase()}.png`)
		t.icon = img.default;
	}))
	
	await Promise.all(data.badges.map(async t => {
				
		let img = await import(`../../img/map/${t.bgColor}.png`)
		t.bg = img.default
		
		img = await import(`../../img/map/${t.taskId.replaceAll('/', '_').toLowerCase()}.png`)
		t.icon = img.default;
	}))


	return data
}

export async function getMapTasks(taskIds)
{
	let data = getMap();

	let tasks = taskIds.map(taskId => {
		let parts = taskId.split('.');

		for(let l = 0; l < data.levels.length; l++)
		{
			let subject = data.levels[l].subjects.find(s => s.id == parts[0])
			if(!subject)
				continue;

			let task = subject.tasks.find(st => st.id == taskId);
			if(task)
				return task;
		}

		return data.bonuses.find(t => t.id == taskId)
	})

	await Promise.all(tasks.map(async t => {
				
		let img = await import(`../../img/map/${t.bgColor}.png`)
		t.bg = img.default
		
		img = await import(`../../img/map/${t.taskId.replaceAll('/', '_').toLowerCase()}.png`)
		t.icon = img.default;
	}))

	return tasks;
}

export function getMapTasksForFlightLog(userMap)
{
	let standardTasks = userMap.levels.flatMap(l => l.subjects.flatMap((s, si) => s.tasks.filter(t => t.mode != 'future' && !t.complete).map((t, ti) => ({
		sort: si * 100 + ti,
		value: t.id,
		text: t.name,
		icon: t.icon,
		bg: t.bg,
		subjectId: s.id
	}))))

	let bonusTasks = userMap.bonuses.filter(t => !t.complete && t.unlocked).map((t, ti) => ({
		sort: t.subject.index * 100 + ti,
		value: t.id,
		text: t.name,
		icon: t.icon,
		bg: t.bg,
		subjectId: t.subject.id
	}))

	let allTasks = standardTasks.concat(bonusTasks)

	allTasks.sort((a, b) => a.sort - b.sort)

	return allTasks;
}