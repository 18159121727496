<script>
export const pageName = 'rockets-flight-log-view';
export const pageRoute = '/rockets/{rocketId}/flights/{flightId}/logs/{logId}';
export const title = 'View Flight Log'
export const requireMembership = true;

import { getProjection } from '../../js/lib/client-read-model.js';
import { getCurrentUserId } from '../../js/auth.js';
import UserView from '../../../functions/domain/users/userView.js';
import RocketView from '../../../functions/domain/rockets/rocketView.js';
import DateTime from '../../../functions/lib/dateTime.js';
import { functions } from '../../js/lib/functions.js';
import { nextTick } from 'vue';
import Chart from 'chart.js/auto'
import zoomPlugin from '@trullock/chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation'

Chart.register(annotationPlugin);
Chart.register(zoomPlugin);

// Stop category scales from zooming/panning
zoomPlugin.zoomFunctions.category = () => false
zoomPlugin.panFunctions.category = () => false
// don't zoom continuity
const defaultFn = zoomPlugin.zoomFunctions.default;
zoomPlugin.zoomFunctions.default = (scale, zoom, center, limits) => {
	if(scale.id == 'continuity')
		return false;

	return defaultFn(scale, zoom, center, limits);
}

export default {
	data() {
		return { 
			rocket: null,
			flight: null,
			flightLog: null,
			log: null,
			showActions: false,
			showEvents: false,
			showResetGraph: false,
			DateTime,
			ready: false
		}
	},

	methods: {
		boot()
		{
			this.leafletLoaded = new Promise((resolve, reject) => {
				var s;
				s = document.createElement('link');
				s.rel = 'stylesheet';
				s.type = 'text/css';
				s.href = 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
				s.onload = resolve;
				s.onerror = reject;
				document.head.appendChild(s);
			}).then(() =>{
				return new Promise(function (resolve, reject) {
					var s;
					s = document.createElement('script');
					s.src = 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.js';
					s.onload = resolve;
					s.onerror = reject;
					document.head.appendChild(s);
				});
			});

			this.leafletLoaded.then(() => {
			}, e => {
				console.error(e)
				this.$noMap.$('span').textContent = 'Error loading map'
			});
		},
		resetZoom()
		{
			this.chart.resetZoom();
		},
		setShowEventsAndActions()
		{
			this.chart.options.plugins.annotation.annotations.forEach(a => a.display = (a._type == 'event' && this.showEvents) || (a._type == 'action' && this.showActions));
			this.chart.update();
		},
		renderGraph()
		{
			this.showActions = true;
			this.showEvents = false;

			let chartAnnotations = this.log.events.filter(e => e.graphType).map((e, i) => {
				return {
					type: 'line',
					borderColor: 'rgb(110, 132, 163)',
					borderWidth: 1,
					display: e.graphType == 'action', // show Actions by default
					_type: e.graphType,
					index: i,
					label: {
						display: true,
						content: e.label,
						backgroundColor: 'rgba(255,255,255,0.75)',
						padding: 1,
						color: '#000',
						font: {
							family: '"Cerebri Sans", sans-serif',
							size: 10,
							weight: 'normal'
						},
						position: (ctx, annotation) => {
							return annotation.index % 2 ? 'end' : 'start';
						},
						xAdjust: (ctx, annotation) => {
							// BUG: the last even label at t=max gets clipped, so bot -8 for now instead of 8 : -8
							return annotation.index % 2 ? -8 : -8;
						},
						rotation: 'auto'
					},
					scaleID: 'x',
					value: e.time,
					z: 99
				}
			});

			let continuityMin = this.log.cfg.usedOutputs.length > 0 ? this.log.cfg.usedOutputs[0].channel : 0;
			let continuityMax = this.log.cfg.usedOutputs.length > 0 ? this.log.cfg.usedOutputs.length == 1 ? this.log.cfg.usedOutputs[0].channel : this.log.cfg.usedOutputs[this.log.cfg.usedOutputs.length - 1].channel : 0;
			if(continuityMax == 1)
				continuityMax = 2;
			if(continuityMin == continuityMax)
				continuityMin -= 1;

			var chartOpts = {
				maintainAspectRatio: false,
				responsive: true,
				animation: false,
				scales: {
					x: {
						type: 'linear',
						title: {
							display: true,
							text: 'Time (s)',
							font: {
								family: '"Cerebri Sans", sans-serif',
								size: 11
							}
						},
						ticks: {
							color: '#6E84A3',
							font: {
								family: '"Cerebri Sans", sans-serif',
								size: 11
							},
							// needed to fix some weird precision display bug
							callback: (v, i, t) => {
								return v.toFixed(1);
							}
						},
						grid: {
							color: '#E3EBF6'
						},
						border: {
							display: false,
							dash: [2, 2]
						},
						min: (this.log.flightData[0].time >= 0 ? this.log.flightData[0].time - 2 : this.log.flightData[0].time),
						max: (this.log.flightData[this.log.flightData.length - 1].time)
					},
					altitude: {
						type: 'linear',
						position: 'left',
						stack: this.log.inputData.length || this.log.continuityData.length ? 'flight' : undefined,
						stackWeight: this.log.inputData.length || this.log.continuityData.length ? 2 : undefined,
						weight: 1,
						title: {
							display: true,
							text: 'Altitude (m)',
							font: {
								family: '"Cerebri Sans", sans-serif',
								size: 11
							},
							color: '#2C7BE5'
						},
						ticks: {
							color: '#2C7BE5',
							font: {
								family: '"Cerebri Sans", sans-serif',
								size: 11
							}
						},
						grid: {
							tickColor: '#fff',
							color: '#E3EBF6'
						},
						border: {
							display: false,
							dash: [2, 2]
						}
					},
					speed: {
						type: 'linear',
						position: 'right',
						stack: this.log.inputData.length || this.log.continuityData.length ? 'flight' : undefined,
						stackWeight: this.log.inputData.length || this.log.continuityData.length ? 2 : undefined,
						weight: 3,
						title: {
							display: true,
							text: 'Speed (m/s)',
							font: {
								family: '"Cerebri Sans", sans-serif',
								size: 11
							},
							color: '#e63757'
						},
						ticks: {
							color: '#e63757',
							font: {
								family: '"Cerebri Sans", sans-serif',
								size: 11
							}
						},
						gridLines: {
							drawOnChartArea: false
						},
						border: {
							display: false
						},
						grid: {
							display: false
						}
					},
					leftInput: {
						type: 'category',
						labels: ['Open', 'Closed'],
						display: this.log.inputData.length > 0,
						ticks: {
							display: true,
							color: '#2C7BE5',
							font: {
								family: '"Cerebri Sans", sans-serif',
								size: 11
							}
						},
						offset: true,
						position: 'left',
						stack: this.log.inputData.length > 0 ? 'flight' : 'hide-me',
						stackWeight: 1,
						weight: 3,
						title: {
							display: this.log.inputData.length > 0,
							text: this.log.inputData.length > 0 ? this.log.inputData[0].label : '',
							font: {
								family: '"Cerebri Sans", sans-serif',
								size: 11
							},
							color: '#2C7BE5'
						},
						gridLines: {
							drawOnChartArea: false
						},
						grid: {
							tickColor: '#fff',
							color: '#E3EBF6'
						},
						border: {
							display: false,
							dash: [2, 2]
						}
					},
					continuity: {
						type: 'linear',
						display: this.log.continuityData.length,
						offset: true,
						position: 'left',
						stack: this.log.continuityData.length ? 'flight' : 'hide-me',
						stackWeight: 1,
						weight: 2,
						min: continuityMin,
						max: continuityMax,
						title: {
							display: true,
							text: 'Continuity',
							font: {
								family: '"Cerebri Sans", sans-serif',
								size: 11
							},
							color: '#e63757'
						},
						ticks: {
							count: this.log.cfg.usedOutputs.length,
							color: '#e63757',
							font: {
								family: '"Cerebri Sans", sans-serif',
								size: 11
							}
						},
						gridLines: {
							drawOnChartArea: false
						},
						border: {
							display: false
						},
						grid: {
							display: false
						}
					},
					continuitySpacer: {
						type: 'linear',
						display: false,
						offset: true,
						position: 'right',
						stack: this.log.continuityData.length ? 'flight' : 'hide-me',
						stackWeight: 1,
						weight: 2, 
						min: continuityMin,
						max: continuityMax,
						gridLines: {
							drawOnChartArea: false
						},
						border: {
							display: false
						},
						grid: {
							display: false
						}
					},
					rightInput: {
						type: 'category',
						labels: ['Open', 'Closed'],
						display: this.log.inputData.length > 1,
						ticks: {
							display: true,
							color: '#e63757',
							font: {
								family: '"Cerebri Sans", sans-serif',
								size: 11
							}
						},
						offset: true,
						position: 'right',
						stack: this.log.inputData.length > 1 ? 'flight' : 'hide-me',
						stackWeight: 1,
						weight: 1,
						title: {
							display: this.log.inputData.length > 1,
							text: this.log.inputData.length > 1 ? this.log.inputData[1].label : '',
							font: {
								family: '"Cerebri Sans", sans-serif',
								size: 11
							},
							color: '#e63757'
						},
						gridLines: {
							drawOnChartArea: false
						},
						grid: {
							tickColor: '#fff',
							color: '#E3EBF6'
						},
						border: {
							display: false,
							dash: [2, 2]
						}
					}
				},
				elements: {
					point: {
						pointStyle: false
					}
				},
				plugins: {
					legend: {
						display: false
					},
					annotation: {
						annotations: chartAnnotations
					},
					tooltip: {
						callbacks: {
							title: context => `${context[0].parsed.y.toFixed(2)} ${context[0].dataset.yAxisID == 'altitude' ? 'm' : 'm/s'}`,
							label: context => `${Math.abs(context.parsed.x)}s`
						}
					},
					zoom: {
						pan: {
						enabled: true,
						mode: 'xy',
						},
						zoom: {
							wheel: {
								enabled: true,
							},
							pinch: {
								enabled: false
							},
							mode: 'xy',
							onZoomComplete: e => {
								let zoom = e.chart.getZoomLevel()
								chartOpts.plugins.zoom.pan.enabled = zoom > 1;
								e.chart.canvas.style.touchAction = zoom > 1 ? 'none' : 'inherit'
								this.showResetGraph = zoom != 1;
							}
						},
						limits: {
							continuity: {
								min: 'original',
								max: 'original'
							},
							inputs: {
								min: 'original',
								max: 'original'
							},
							x: {
								min: 'original',
								max: 'original'
							},
							speed:
							{
								min: 'original',
								max: 'original'
							},
							altitude:
							{
								min: 'original',
								max: 'original'
							}
						}
					}
				}
			};

			var chartConfig = {
				type: 'line',
				options: chartOpts,
				data: {
					datasets: [
						{
							yAxisID: 'altitude',
							label: 'Altitude',
							data: this.log.flightData.map(d => ({ x: (d.time), y: d.altitude})),
							borderWidth: 1,
							borderColor: '#2c7be5', // primary
							// To make the tooltip legend display nicely
							backgroundColor: '#2c7be5',
							//cubicInterpolationMode: 'monotone',
							tension: 0.4
						},
						// {
						// 	yAxisID: 'altitude',
						// 	label: 'Raw altitude',
						// 	data: this.json.flightData.map(d => ({ x: (d.time), y: d.rawAltitude})),
						// 	borderWidth: 1,
						// 	borderColor: '#39afd1', // cyan
						// 	//cubicInterpolationMode: 'monotone',
						// 	tension: 0.4
						// },
						{
							yAxisID: 'speed',
							label: 'Speed',
							data: this.log.flightData.map((d, i) => ({ x: (d.time), y: i <= 1 ? null : d.speed})), // filter off the first few values as they are meaningless to this derived property
							borderColor: '#e63757',
							// To make the tooltip legend display nicely
							backgroundColor: '#e63757',
							borderWidth: 1,
							//cubicInterpolationMode: 'monotone',
							tension: 0.4
						}
					]
				}
			};

			for(var i = 0; i < this.log.continuityData.length; i++)
			{
				let dataset = this.log.continuityData[i];

				chartConfig.data.datasets.push({
					label: dataset.label,
					data: dataset.data.map(d => ({ x: (d.time), y: d.channel, z: d.state })),
					borderColor: '#2c7be5',
					segment: {
						borderColor: ctx => ctx.p0.raw.z ? '#00D97E' : '#e63757'
					},
					borderWidth: 2,
					yAxisID: 'continuity'
				})

			}

			for(var i = 0; i < this.log.inputData.length; i++)
			{
				let dataset = this.log.inputData[i];
				
				chartConfig.data.datasets.push({
					label: dataset.label,
					data: dataset.data.map(d => ({ x: (d.time), y: d.state ? 'Closed' : 'Open'})),
					borderColor: i == 0 ? '#2c7be5' : '#e63757',
					borderWidth: 1,
					stepped: true,
					yAxisID: i == 0 ? 'leftInput' : 'rightInput'
				})
			}

			
			this.chart = new Chart(this.$refs.chart, chartConfig);
			this.chart.canvas.style.touchAction = 'inherit'
		},
		renderMap()
		{
			if(this.log.location.latitude != 0 && this.log.location.longitude != 0)
			{
				this.leafletLoaded.then(() => {
					this.map = L.map(this.$refs.map.firstElementChild).setView([this.log.location.latitude, this.log.location.longitude], 13);
					L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
						attribution: '',
						maxZoom: 18,
						id: 'mapbox/streets-v11',
						tileSize: 512,
						zoomOffset: -1,
						accessToken: 'pk.eyJ1IjoidHJ1bGxvY2siLCJhIjoiY2toMjQwNDljMGIwNDJ5bXh3emptZ2VlNiJ9.bADZ24oDAaisuG8tIrJbjw'
					}).addTo(this.map);
					var marker = L.marker([this.log.location.latitude, this.log.location.longitude]).addTo(this.map);
				})
			}
		},
		async show(opts) {

			this.rocket = await getProjection([UserView, getCurrentUserId(), RocketView, opts.rocketId])
			this.flight = this.rocket.getFlight(opts.flightId);
			this.flightLog = this.flight.logs.find(l => l.id == opts.logId)

			this.log = await functions.rocketFlightLogVisualise({ 
				rocketId: opts.rocketId,
				flightId: opts.flightId,
				logId: this.flightLog.id
			});

			this.ready = true;

			await nextTick();

			this.renderGraph();
			setTimeout(() => {
				this.renderMap();
			}, 1000);
		},
		getMachRegime(speed)
		{
			let mach = Math.abs(speed) / 343;

			if(mach < 0.8)
				return `Subsonic - Mach ${mach.toFixed(1)}`;

			if(mach < 1.2)
				return `Transonic - Mach ${mach.toFixed(1)}`;

			if(mach < 5.0)
				return `Supersonic - Mach ${mach.toFixed(1)}`;
			
			if(mach < 10.0)
				return `Hypersonic - Mach ${mach.toFixed(1)}`;

			if(mach < 25.0)
				return `High-hypersonic - Mach ${mach.toFixed(1)}`;

			return `Re-entry speeds - Mach ${mach.toFixed(1)}`;
		},
		hide(opts){
			this.chart.destroy();
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row justify-content-center">
			<div class="col-12 my-5">
				<h1 class="display-4 text-center mb-3">View Flight Log</h1>
				<p class="text-muted text-center mb-3">Visualise flight data</p>
				<p class="alert alert-light text-center mb-5"><span class="fe fe-info"></span> Not all information is available from all vendors' flight logs and/or formats.<br />UKRA does not endorse any particular vendor or flight computer.<br />This tool is still a beta version. If you find any issues please <a href="mailto:membership@ukra.org.uk">let us know</a>.</p>
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Flight details</h4>
					</div>
					<div class="card-body py-0">
						
						<div class="list-group list-group-flush">
						
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<small>Configuration</small>
									</div>
									<div class="col text-right">
										<small>{{ log.cfg.name }}</small>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<small>Date</small>
									</div>
									<div class="col text-right">
										<small>{{ flight.flownOn.format('yyyy/MM/dd HH:mm') }}</small>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<small>Location</small>
									</div>
									<div class="col text-right">
										<small>{{ log.location?.name || 'Unknown'}}</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title d-none d-sm-block">Visualisation</h4>
						<div class="row justify-content-end align-items-center">
							<button v-if="showResetGraph" class="btn btn-sm btn-outline-primary mr-4" @click.prevent="resetZoom">Reset zoom</button>
							<div class="custom-control custom-switch mr-4">
								<input type="checkbox" class="custom-control-input" v-model="showActions" id="chkViewFlightShowActions" @change="setShowEventsAndActions">
								<label class="custom-control-label" for="chkViewFlightShowActions">Actions</label>
							</div>
							<div class="custom-control custom-switch">
								<input type="checkbox" class="custom-control-input" v-model="showEvents" id="chkViewFlightShowEvents" @change="setShowEventsAndActions">
								<label class="custom-control-label" for="chkViewFlightShowEvents">Events</label>
							</div>
						</div>
					</div>
					<div class="pt-4 pb-4 px-3">
						<div class="chart chart-tall">
							<canvas class="chart-canvas" ref="chart"></canvas>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Stats</h4>
					</div>
					<div class="card-body py-0">
						<div class="list-group list-group-flush">
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										<small>Max altitude</small>
									</div>
									<div class="col text-right">
										<small>{{ log.maxAlt.toFixed(2)}}m @ {{ log.maxAltTime.toFixed(2) }}s</small>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										<small>Max ascent speed</small>
									</div>
									<div class="col text-right">
										<small>~{{ log.maxAscentSpeed.toFixed(2)}}m/s</small>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										<small>Max ascent regime</small>
									</div>
									<div class="col text-right">
										<small>{{ getMachRegime(log.maxAscentSpeed) }}</small>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										<small>Max descent speed</small>
									</div>
									<div class="col text-right">
										<small>~{{ log.maxDescentSpeed.toFixed(2)}}m/s</small>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										<small>Median descent speed</small>
									</div>
									<div class="col text-right">
										<small>~{{ log.avgDescentSpeed.toFixed(2)}}m/s</small>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										<small>Landing speed</small>
									</div>
									<div class="col text-right">
										<small>~{{ log.landingSpeed.toFixed(2)}}m/s</small>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<span class="small text-muted">~Values are inferred and not raw data</span>
					</div>
				</div>
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Configuration</h4>
					</div>
					<div class="card-body py-0">
						<div v-if="log.cfg.populated" class="list-group list-group-flush">
							<div v-for="rule of log.cfg.rules" class="list-group-item">
								<h5 class="mb-1 js-label">{{ rule.label }}</h5>
								<span class="small text-gray-700 mb-0 d-block">{{ rule.trigger }}</span>
								<span class="small text-gray-700 mb-0 d-block">{{ rule.action }}</span>
								<span :class="`small mb-0 d-block ${rule.occurredStyle}`">{{ rule.occurredText }}</span>
							</div>
						</div>
						<div v-else class="py-4">
							<p class="text-muted text-center small mb-0">Flight configuration information is only available on Trullock Aerospace Gagarin and Grissom detailed flight logs.</p>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Events</h4>
					</div>
					<div class="card-body py-0">
						<div class="list-group list-group-flush">
							<div v-if="log.eventsMode" class="list-group-item">
								<p class="text-muted text-center small mb-0">{{ log.eventsMode }}</p>
							</div>
							<div v-for="event of log.events" class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto pr-0">
										<span :class="`fe ${event.icon} text-primary`"></span>
									</div>
									<div class="col">
										<small>{{event.label}}</small>
										<div class="small text-muted">{{event.trigger}}</div>
										<div class="small text-muted">{{event.stats}}</div>
										<div v-if="event.skipped" class="small text-danger mb-0">{{event.skipped}}</div>
									</div>
									<div class="col-auto text-right">
										<small class="text-muted">{{event.value}}</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Location</h4>
					</div>
					<div class="card-body pt-0">
						<div class="list-group list-group-flush">
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<small>Location</small>
									</div>
									<div class="col text-right">
										<small>{{ log.location?.name || 'Unavailable' }}</small>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<small>Coordinates</small>
									</div>
									<div class="col text-right">
										<small>{{ log.location?.latitude ? log.location.latitude.toFixed(6) + ' x ' + log.location.longitude.toFixed(6) : 'Unavailable' }}</small>
									</div>
								</div>
							</div>
						</div>
						<div v-if="log.location?.latitude" ref="map" class="map">
							<div class="mb-1" style="height: 50vh"></div>
							<p class="small text-muted">Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a></p>
						</div>
						<div v-if="!log.location?.latitude" ref="no-map" class="no-map border text-center align-items-center justify-content-center" style="height: 15vh; display: flex;">
							<span class="small text-muted" ref="mapError">No location data</span>
						</div>
					</div>
				</div>	
				<p class="text-muted text-center">Copyright &copy; {{ DateTime.now.year }} Trullock Aerospace. Used under license by UKRA.</p>	
			</div>
		</div>
    </div>
						
</template>
