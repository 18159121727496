<script>

export const requireAuth = false;
export const pageName = 'account-sign-up';
export const pageRoute = '/account/sign-up';
export const title = 'Sign-up'

import { validation } from '../../js/language.js'
import {showToast} from '../../js/utils/toast.js'
import { getAuth, updateProfile, OAuthProvider, GoogleAuthProvider, GithubAuthProvider, signInWithPopup, signInWithEmailAndPassword, fetchSignInMethodsForEmail, createUserWithEmailAndPassword, getAdditionalUserInfo, EmailAuthProvider, linkWithCredential } from 'firebase/auth'
import { countPasswordBreaches } from '../../js/lib/hibp.js';
import { navigate, PageShowError } from '@trullock/page-manager';
import { functions } from '../../js/lib/functions.js';
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { userChanged } from '../../js/auth.js';

export default {
	data() {
		return {
			name: '',
			email: '',
			password: '',
			agree: false
		 }
	},
	methods: {
		ssoGithub: function(){
			var provider = new GithubAuthProvider();
			this.signInWithSSO(provider, 'Account successfully linked to your Github account');
		},
		ssoGoogle: function(){
			var provider = new GoogleAuthProvider();
			this.signInWithSSO(provider, 'Account successfully linked to your Google account');
		},
		ssoMicrosoft: function(){
			var provider = new OAuthProvider('microsoft.com');
			this.signInWithSSO(provider, 'Account successfully linked to your Microsoft account');
		},
		submit: async function(){
			this.resetAuthErrors();

			showLoading();
			
			let breaches = await countPasswordBreaches(this.password)
			if(breaches > 0)
			{
				this.$refs.password.setCustomValidity(validation.messages.breached(breaches));
				this.$refs.password.reportValidity();
				hideLoading();
				return;
			}
		

			let result = null;
			
			let userLoggedInP = userChanged(u => !!u);

			try
			{
				result = await createUserWithEmailAndPassword(getAuth(), this.email, this.password)
				await updateProfile(result.user, {
					displayName: this.name
				})
			}
			catch(error)
			{
				if(error.code == 'auth/email-already-in-use')
				{
					let methods = await fetchSignInMethodsForEmail(getAuth(), this.email);
					if(methods.indexOf('password') > -1)
					{
						try
						{
							let userChangedP = userChanged();
							await signInWithEmailAndPassword(getAuth(), this.email, this.password);
							await userChangedP;

							navigate('goal')

							hideLoading();
							return;
						}
						catch(e)
						{
							console.error(e);
							this.handleEmailAlreadyInUse(methods, 'auth/email-already-in-use')
							
							hideLoading();

							return;
						}
					}

					this.pendingCred =  EmailAuthProvider.credential(this.email, this.password);
					this.pendingCredLinkedMessage = 'Password successfully associated with account';
					return this.handleEmailAlreadyInUse(methods, 'auth/account-exists-with-different-credential');
				}

				this.$refs.email.setCustomValidity(validation[error.code] || validation['error']);
				this.$refs.email.reportValidity();
				hideLoading();
				return;
			}

			
			await functions.userCreate({ name: this.name });
			await result.user.getIdToken(true);

			await userLoggedInP;
						
			navigate('goal', { fallback: '/account/complete' });
			
			hideLoading();
		},
		signInWithSSO(provider, linkedMessage){
			this.resetAuthErrors();
			this.$refs.form.reset();
			
			showLoading('Waiting for response from authentication popup...');

			// filter null because initially it "changes" to null as the listener starts looking for the new user that doesnt exist yet
			let userChangedP = userChanged(u => !!u);

			signInWithPopup(getAuth(), provider).then(async signInResult => {
				
				let info = getAdditionalUserInfo(signInResult);
				if(info.isNewUser)
				{
					showLoading('Creating account...');

					await functions.userCreate({ name: signInResult.user.displayName });
					await signInResult.user.getIdToken(true);
				}
				else
				{
					// TODO: this is hacky, can we just do this in the backend when necessary?
					await functions.userUpdateEmailVerified();
				}

				await userChangedP;
				
				navigate('goal', { fallback: info.isNewUser ? '/account/complete' : '/'});

				if(this.pendingCred)
				{
					linkWithCredential(signInResult.user, this.pendingCred).then(result => {
						showToast({
							style: 'bg-success',
							message: this.pendingCredLinkedMessage
						})
					}, e => console.error(e));
				}
			}).catch(error => {
				
				if(error.code == 'auth/account-exists-with-different-credential')
				{
					this.handleExistingAuth(error, linkedMessage);
					return;
				}
				console.error(error);
				
				this.$refs.email.setCustomValidity(validation[error.code] || validation['error']);
				this.$refs.email.reportValidity();
			}).finally(() => {
				hideLoading();
			});
		},
		resetAuthErrors: function() {
			this.$refs.btnGoogle.classList.remove('glow');
			this.$refs.btnGithub.classList.remove('glow');
			this.$refs.btnMicrosoft.classList.remove('glow');
			this.$refs.password.classList.remove('glow');
		},
		handleEmailAlreadyInUse: function(methods, errorCode){
			this.glowAuthProviderMethods(methods);
			this.$refs.email.setCustomValidity(validation[errorCode]);
			this.$refs.email.reportValidity();
			hideLoading();
		},
		handleExistingAuth: async function(error, linkedMessage){
			this.pendingCred = error.credential;
			this.pendingCredLinkedMessage = linkedMessage;
			this.$refs.email.value = error.email;

			let methods = await fetchSignInMethodsForEmail(getAuth(), error.email);
			this.glowAuthProviderMethods(methods);

			this.$refs.email.setCustomValidity(validation[error.code]);
			this.$refs.email.reportValidity();
		},
		glowAuthProviderMethods: function(methods) {
			if(methods.indexOf('google.com') > -1)
				this.$refs.btnGoogle.classList.add('glow');
			if(methods.indexOf('github.com') > -1)
				this.$refs.btnGithub.classList.add('glow');
			if(methods.indexOf('microsoft.com') > -1)
				this.$refs.btnMicrosoft.classList.add('glow');
			if(methods.indexOf('password') > -1)
				this.$refs.password.classList.add('glow');
		},
		show(opts) {
			if(getAuth().currentUser)
				throw new PageShowError('/', 'Already signed in', {}, 'replace')
				
			functions.warmUp.userCreate();

			this.resetAuthErrors();
			this.pendingCred = null;
			this.pendingCredLinkedMessage = null;
			this.$refs.form.reset();
		}
	}
}

</script>
<template>
	<div class="container mt-5">
		<div class="row justify-content-center">
			<div class="col-12 col-md-6">
				<div class="text-center">
					<h1 class="display-4 mb-3">Create an account</h1>
					<p class="px-4 text-muted">Sign up to access member benefits</p>
				</div>
			</div>
		</div>
		<div class="row justify-content-center mt-4">
			<div class="col-12 col-md-6 my-5">
				<div class="text-center mb-4">
					<button @click.prevent="ssoGoogle" ref="btnGoogle" class="btn btn-white btn-auth"><img alt="Google icon" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" width="18" height="18"> Sign up with Google</button>
				</div>
				<div class="text-center mb-2 d-none">
					<button @click.prevent="ssoGithub" ref="btnGithub" class="btn btn-white btn-auth"><img alt="Github icon" src="../../img/github.svg" width="18" height="18"> Sign up with Github</button>
				</div>
				<div class="text-center mb-4 d-none">
					<button @click.prevent="ssoMicrosoft" ref="btnMicrosoft" class="btn btn-white btn-auth"><img alt="Microsoft icon" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/microsoft.svg" width="18" height="18"> Sign up with Microsoft</button>
				</div>
				<hr class="mb-4" />
				<form ref="form" @submit.prevent="submit">
					<div class="form-group mb-4">
						<label>Name</label>
						<input type="text" class="form-control" v-model="name" autocomplete="name" placeholder="First Last" required tabindex="1" />
						<span class="invalid-feedback"></span>
					</div>
					<div class="form-group mb-4">
						<label>Email address</label>
						<input type="email" class="form-control" v-model="email" ref="email" autocomplete="email" placeholder="your.name@example.com" required tabindex="1" />
						<span class="invalid-feedback"></span>
					</div>
					<div class="form-group mb-4">
						<label>Password</label>
						<div class="input-group has-validation">
							<input type="password" v-model="password" ref="password" placeholder="e.g. Something long and unique" class="form-control form-control-appended" autocomplete="new-password" required minlength="8" tabindex="3">
							<div class="input-group-append">
								<button class="btn btn-outline-secondary js-reveal" tabindex="-1" type="button"><span class="fe fe-eye"></span><span class="sr-only">Show/Hide password</span></button>
							</div>
							<span class="invalid-feedback"></span>
						</div>
						<span class="form-text small text-muted mt-2 mb-0">8 characters or more, must not be a <a href="/account/password-strength" target="_blank">common password <span class="fe fe-external-link"></span></a>.</span>
					</div>
					<div class="form-group">
						<div class="custom-control custom-checkbox">
							<input type="checkbox" class="custom-control-input" v-model="agree" value="on" required tabindex="3">
							<label class="custom-control-label">I agree to the <a href="/terms-of-service" target="_blank">terms of service <span class="fe fe-external-link"></span></a> and <a href="/privacy-policy" target="_blank">privacy policy <span class="fe fe-external-link"></span></a></label>
							<span class="invalid-feedback"></span>
						</div>
					</div>
					<button ref="btnSignUp" type="submit" class="btn btn-primary btn-block" tabindex="4">Sign up</button>
					<p class="small text-muted text-center mt-3">Already have an account? <a href="/account/sign-in" tabindex="5">Sign in</a>.</p>
				</form>
			</div>
		</div>
</div>
</template>